import React, {Fragment} from 'react'
import styles from './custom-gallery-layout.module.css'

const mapStyle = (item) => {
  let s = {}
  // s.backgroundColor = '#ddd'
  if(item.colspan) {
    s.gridColumnEnd = `span ${item.colspan}`
  }
  if(item.rowspan) {
    s.gridRowEnd = `span ${item.rowspan}`
  }
  s.height = '100%'
  // Allow style overrides
  if(item.style) {
    s = Object.assign(s, item.style)
  }
  return s
}

export default ({items, onItemClick}) => (

  <Fragment>
    <div className="only-desktop">
      <div className={styles.container}>
        {(items || []).map((item, index) => (
          <div key={item.public_id} className={styles.itemDesktop} style={mapStyle(item)}
                onClick={e => {
                  if(onItemClick) onItemClick(index, item)
                }}>
            <img src={item.secure_thumb_url} style={{ height: '100%', objectFit:'cover' }}/>
          </div>
        ))}
      </div>
    </div>

    <div className="only-mobile">
      {(items || []).map((item, index) => (
        <div key={item.public_id} className={styles.itemMobile} 
            onClick={e => {
              if(onItemClick) onItemClick(index, item)
            }} >
          <img src={item.secure_thumb_url} />
        </div>
      ))}
    </div>      
  </Fragment>


)