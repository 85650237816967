import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Masonry from 'react-masonry-css'
import Header from '../components/header'
import Footer from '../components/footer'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Lightbox from '../components/lightbox'
import projectStyles from './project.module.css'
import CustomGalleryLayout from '../components/custom-gallery-layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import mockLayoutData from '../../data/mock-layout-landscape.json'
import { addCloudinaryTransformPreset, preprocessGalleryMedia } from '../lib/util'

const useMockLayout = false

const breakpointColumnsConfig = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1
};

class PaintCollectionTemplate extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false,
      currentImageIndex: 0
    }
  }

  showLightbox(index) {
    const collection = get(this.props, 'data.contentfulPaintCollection')
    this.setState({ showLightbox: true, currentImageIndex: index })
  }

  render() {
    const collection = get(this.props, 'data.contentfulPaintCollection')
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const srcMedia = get(this, 'props.data.allCloudinaryMedia.edges')
    const { showLightbox, currentImageIndex } = this.state 
    
    const useLayout = (useMockLayout || collection.layoutConfig != undefined)
    const layoutConfig = useMockLayout ? mockLayoutData : collection.layoutConfig
    let paintings = collection.paintings
    if(useLayout) {
      paintings = preprocessGalleryMedia(
                    layoutConfig, 
                    srcMedia,
                    { 
                      defaultThumbTransform: 'c_fit,e_sharpen:100,h_640,w_640,q_80',
                      defaultTransform: 'c_fit,e_sharpen:100,h_1920,w_1080,q_80',
                      sizeVariantThumbTransform: [
                        'c_fit,e_sharpen:100,h_320,w_320,q_80',
                        'c_fit,e_sharpen:100,h_640,w_640,q_80',
                        'c_fit,e_sharpen:100,h_960,w_960,q_80'
                      ] 
                    }
                  )
    } else {
      paintings = preprocessGalleryMedia(
                    collection.paintings, 
                    srcMedia,
                    { 
                      defaultThumbTransform: 'c_fit,e_sharpen:100,h_480,w_480,q_80',
                      defaultTransform: 'c_fit,e_sharpen:100,h_1920,w_1080,q_80' 
                    }
                  )
    }
    

    const pageDescription = collection.description ? documentToPlainTextString(collection.description.json) : siteMetadata.description

    return (
      <div>
        <Helmet>
          <title>{`${collection.title} | ${siteTitle}`}</title>
          <meta name="description" content={pageDescription} />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${collection.title} | ${siteTitle}`} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={addCloudinaryTransformPreset(collection.coverImage[0].secure_url, 'og_image')} />
        </Helmet>
        <Header data={author.node} />

        <div className="wrapper">
          {useLayout &&
            <CustomGalleryLayout 
              items={paintings}
              onItemClick={(index, item) => this.showLightbox(index)}/>
          }

          {!useLayout &&
            <Masonry
              breakpointCols={breakpointColumnsConfig}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {paintings.map((media, index) => (
                <div key={media.public_id} style={{ cursor: 'pointer' }}  onClick={e => {
                  this.showLightbox(index)
                }}>
                  <img className={projectStyles.media} alt={media.title} src={media.secure_thumb_url} fadeIn/>
                  
                </div>
              ))}
            </Masonry>
          }
          
          <br /><br />
          {collection.description &&
            <div>
              {documentToReactComponents(collection.description.json)}
            </div>
          }
        </div>

        <Footer data={author.node} />

        {showLightbox && 
          <Lightbox 
            assets={paintings} 
            currentIndex={currentImageIndex}
            onClose={() => {
              this.setState({ showLightbox: false })
            }}
            onNext={() => {
              this.setState({ currentImageIndex: (currentImageIndex + 1) % paintings.length })
            }}
            onPrev={() => {
              this.setState({ currentImageIndex: ((currentImageIndex+paintings.length) - 1) % paintings.length })
            }} />
        }
      </div>
    )
  }
}

export default PaintCollectionTemplate

export const pageQuery = graphql`
  query PaintCollectionBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
      }
    }

    contentfulPaintCollection(slug: { eq: $slug }) {
      title
      coverImage {
        url
        secure_url
      }
      layoutConfig {
        public_id
        colspan
        rowspan
        thumb_transform
        style {
          margin
          padding
          height
        }
      }
      paintings {
        public_id
        url
        secure_url
      }
      description {
        json
      }
    }

    allCloudinaryMedia(filter: {public_id: {glob: "PAINT/*/*"}}) {
      edges {
        node {
          public_id
          url
          secure_url
          context {
            custom {
              alt
              caption
            }
          }
        }
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          instagram
          email
        }
      }
    }
  }
`
